/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { menus } from "../Parts/sidebarRoutes";
import { ReactComponent as StarRate } from "../assets/images/star-filled.svg";

const ParentDiv = styled.div`
  height: calc(90vh - 300px);
  overflow: overlay;

  .pList {
    margin: 0;
  }
  .pListItem {
    font-size: 13px;
    font-weight: 550;
    letter-spacing: 0.2px;
    padding: 6px 15px;
    color: #bbb;
    display: flex;
    margin: 5px 0 0;

    &.active,
    &.active:hover {
      background: #ebae25;
      border-radius: 5px;
      color: #fff;
      span img {
        opacity: 1;
      }
      .cList {
        background: transparent;
      }
      .pLink {
        color: #fff;
        span img {
          opacity: 1;
        }
      }
      .addIcon {
        color: #fff;
      }
    }
    &:hover {
      background: #3a393e;
      border-radius: 5px;
      .cList {
        background: transparent;
      }
      .pLink {
        color: #fff;
      }
    }
    .pListIcon {
      margin-right: 15px;
      vertical-align: middle;
      img {
        width: 15px;
        height: 15px;
        opacity: 0.7;
      }
    }
    .pLink {
      color: #bbb;
      text-decoration: none;
      display: inline-block;
      width: 90%;
      font-weight: 550;
      letter-spacing: -0.2px;
      padding: 2px 0 0 0;
    }
    .addIcon {
      color: #b2b2b2;
      font-size: 17px;
      padding: 0;
      padding-top: 2px;
      cursor: pointer;
    }

    .review {
      margin: 0;
      color: #ebae25;
      background: #fff;
      padding: 2px 5px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      height: fit-content;
      float: right;
      font-size: 14px;
      line-height: 18px;

      svg {
        width: 11px;
        height: 11px;
        fill: #ebae25;
        padding: 0;
        margin-right: 2px;
      }
    }
  }

  .cList {
    background: transparent;
    list-style-type: none;
    padding: 0 0 2px 10px;
    height: 0px;
    display: none;
    opacity: 0;
    margin: 0;
    &.collapse-in {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.5s;
      animation-name: slideHight;
      transition: height 0.5s;
    }
    &.collapse {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.3s;
      animation-name: slideMaxHight;
      transition: height 0.3s;
    }
    .cListItem {
      height: auto;
      padding: 0 10px;
      font-size: 14px;
      &.active,
      &:hover {
        background: transparent;
        .cLink {
          color: #fff;
        }
      }
      .cListIcon {
        vertical-align: middle;
        svg {
          margin-right: 10px;
          float: none;
          padding: 0px;
        }
      }
      .cLink {
        color: #b2b2b2;
        display: block;
        padding: 3px 0;
        font-size: 14px;
        font-weight: 450;
      }
    }
  }
`;

function GNestedMenu(props) {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [isActive, setIsActive] = useState(false);
  //console.log(location);

  useEffect(() => {
    let activeKey = "";
    const found = menus.some((el) => el.path === location.pathname);
    // console.log(found);
    if (!found) {
      setIsMenuOpen({});
      setIsActive(false);
    }

    menus.map((s) => {
      s.children &&
        s.children.map((el) => {
          if (s.path === location.pathname) {
            setIsMenuOpen(s);
          }
          if (el.path === location.pathname) {
            activeKey = s;
            setIsMenuOpen(s);
          }
        });
    });
  }, [location]);

  const handleToggle = (data) => {
    setIsMenuOpen(data);
    setIsActive(true);
  };

  const renderMenuItem = (menu, i) => {
    const { title, path, state, icon, isLink } = menu;

    return (
      <React.Fragment key={i}>
        <li
          className={
            !state
              ? "pListItem"
              : state === location.state
              ? "pListItem active"
              : "pListItem"
          }
          onClick={() => {
            handleToggle(menu, i);
          }}
          href={"#" + menu.path}
        >
          {isLink === true ? (
            <Link
              className="pLink"
              to={{ pathname: path, state: { state: state } }}
              state={state}
            >
              <span className="pListIcon">
                <img src={icon} alt={title} />
              </span>{" "}
              {title}
            </Link>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "2px",
              }}
            >
              <span className="pListIcon">
                {" "}
                <img src={icon} alt={title} />
              </span>
            </div>
          )}
          {title == "Reviews" ? (
            <span className="review">
              <StarRate /> {props.settings?.user?.rating?.display}
            </span>
          ) : (
            ""
          )}
        </li>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ParentDiv
        mode="inline"
        theme="dark"
        // selectedKeys={selectedKeys}
        // openKeys={openKeys}
        // onClick={handleMenuClick}
        // onOpenChange={handleOpenChange}
      >
        <ul className="pList">
          <>
            {menus
              .filter((f) => f.title)
              .map((menu, i) => renderMenuItem(menu, i))}
          </>
        </ul>
      </ParentDiv>
    </React.Fragment>
  );
}

export default GNestedMenu;
