// theme.js
import React, { useContext } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { ThemeContext } from "./useTheme";

const GlobalStyle = createGlobalStyle`
  * {
  }
`;

const Theme = ({ children }) => {
  const { theme } = useContext(ThemeContext); // get the current theme ('light' or 'dark')
  const themes = {
    light: {
      body: "#ebae25",
      text: "#2d4961",
      toggleBorder: "#FFF",
      gradient: "linear-gradient(#39598A, #ebae25)",
    },
    dark: {
      body: "#363537",
      text: "#FAFAFA",
      toggleBorder: "#6B8096",
      gradient: "linear-gradient(#091236, #1E215D)",
    },
  };

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
