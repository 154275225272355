import React from "react";
import { Grid } from "@mui/material";
import { Card } from "../styledComponents/cardStyles";
import GText from "./GText";
import { ReactComponent as LocalOffer } from "../assets/images/icons/offers.svg";
import { ReactComponent as Assignment } from "../assets/images/icons/bookings.svg";

function GDashboardCounts(props) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} style={{ padding: "2px 8px" }}>
      <Card>
        {props.type === "order" ? (
          <Assignment
            fontSize="small"
            style={{
              background: "#1BAC57",
              fill: "#fff",
              width: 20,
              height: 21,
            }}
          />
        ) : props.type === "offer" ? (
          <LocalOffer
            fontSize="small"
            style={{
              background: "#FFAF00",
              fill: "#fff",
              width: 20,
              height: 21,
            }}
          />
        ) : (
          ""
        )}
        <div
          style={{
            display: "inline-block",
            float: "right",
            textAlign: "right",
          }}
        >
          <GText g5 med text={props.title} style={{ margin: "0" }} />
          <GText
            bold
            g1
            text={props.total_count}
            style={{ margin: "0 0 7px" }}
          />
        </div>
        <GText text={props.subtitle} style={{ margin: "25px 0 0" }} />
      </Card>
    </Grid>
  );
}

export default GDashboardCounts;
