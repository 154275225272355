import React, { useEffect, useState } from "react";
import {
  ApiService,
  addDefaultSrc,
  ImgUrl,
  getProductTitle,
} from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../Elements/gImageEditor";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import GMap from "../Elements/gMaps";

export default function ProductCreateForm(props) {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [productImg, setProductImg] = useState([]);
  const [variantTypeIndex, setVariantTypeIndex] = useState(0);

  const initialValues = props.fields.reduce(
    (a, v) => ({
      ...a,
      [v?.id]: ["checkbox"].includes(v?.type) ? 0 : "",
    }),
    {
      cost: "",
      address: "",
      lat: process.env.REACT_APP_DEFAULT_LATITUDE || 30.7046,
      lng: process.env.REACT_APP_DEFAULT_LONGITUDE || 76.7179,
    }
  );

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = {
      cost: values.cost,
      custom_fields: values,
      host_id: props.hostId,
      address: values.address,
      lat: values.lat,
      lng: values.lng,
      user_cost: values.user_cost || 0,
      users_covered: values.users_covered || 1,
      units: values.units,
      min_users: values.min_users,
      max_users: values.max_users,
    };
    console.log(sendData);
    // return;
    ApiService({
      method: "POST",
      route: "products",
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setPcvisible(false);
        props.onSubmit();
      }
      setIsBtnDisabled(false);
      enqueueSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    cost:
      props.settings?.time_charges == 1
        ? Yup.string().required("Cost is required")
        : "",
    address: Yup.string().required("Address is required"),
  }).shape(
    props.fields
      .filter((t) => t.required)
      .reduce(
        (a, v) => ({
          ...a,
          [(v?.id).toString()]:
            v.type == "checkbox"
              ? ""
              : Yup.string()
                  .nullable()
                  .required(v.title + " is required"),
        }),
        {}
      )
  );

  if (props.pcvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> Add {getProductTitle()}</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {props.settings?.time_charges == 1 && (
                <FormikControl
                  control="input"
                  type="text"
                  label={"Cost (" + props.settings?.billing_type + ")"}
                  name={"cost"}
                  divstyle={{
                    display: "inline-block",
                    width: props.settings?.multiple_units == 0 ? "100%" : "33%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                  labelStyle={{ textTransform: "capitalize" }}
                />
              )}
              {props.settings?.multiple_units > 0 &&
                props.settings?.time_charges == 1 && (
                  <>
                    {" "}
                    <FormikControl
                      control="input"
                      type="text"
                      label={"User Cost"}
                      name={"user_cost"}
                      divstyle={{
                        display: "inline-block",
                        width: "33%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("user_cost", event.target.value);
                        }
                      }}
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      label={"User Covered"}
                      name={"users_covered"}
                      divstyle={{
                        display: "inline-block",
                        width: "33%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("users_covered", event.target.value);
                        }
                      }}
                    />
                  </>
                )}

              {props.settings?.multiple_units == 1 && (
                <>
                  <FormikControl
                    control="input"
                    type="text"
                    label={props.settings?.unit_display + " Count"}
                    name={"units"}
                    divstyle={{
                      display: "inline-block",
                      width: "33%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("units", event.target.value);
                      }
                    }}
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label={"Min Users"}
                    name={"min_users"}
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("min_users", event.target.value);
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "33.3%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label={"Max Users"}
                    name={"max_users"}
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("max_users", event.target.value);
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "33.3%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />
                </>
              )}
              <GMap
                centerLocation={{
                  lat: process.env.REACT_APP_DEFAULT_LATITUDE || 30.7046,
                  lng: process.env.REACT_APP_DEFAULT_LONGITUDE || 76.7179,
                }}
                search={true}
                height="30vh"
                type="createStore"
                onChange={(address, loc) => {
                  values.lat = loc.lat();
                  values.lng = loc.lng();
                  setFieldValue("address", address.address);
                }}
                label="Location"
                padding={true}
              />
              <FormikControl
                control="input"
                type="text"
                label="Address"
                name="address"
              />
              {props.fields.map((f, i) => (
                <React.Fragment key={i}>
                  {f.type == "select" && (
                    <FormikControl
                      control="selectOption"
                      as="select"
                      label={f.title}
                      name={f.id.toString()}
                      options={f.answers}
                      key_title="title"
                      key_value="id"
                      divstyle={{
                        display: "inline-block",
                        width: "50%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                    />
                  )}

                  {f.type == "text" && (
                    <FormikControl
                      control="input"
                      type="text"
                      label={f.title}
                      name={f.id}
                    />
                  )}

                  {f.type == "textarea" && (
                    <FormikControl
                      control="input"
                      as="textarea"
                      label={f.title}
                      name={f.id}
                    />
                  )}

                  {f.type == "checkbox" && (
                    <FormikControl
                      control="singleCheckbox"
                      as="checkbox"
                      label={f.title}
                      name={f.id}
                      checked={values[f.id] == 1 ? true : false}
                      onChange={(e) => {
                        values[f.id] = e.target.checked == true ? 1 : 0;
                        setFieldValue(f.id, e.target.checked == true ? 1 : 0);
                      }}
                    />
                  )}
                </React.Fragment>
              ))}

              <div style={{ margin: "15px" }}>
                <GText semi g2 text={getProductTitle() + " Images"} />
                {productImg.map((b, ind) => {
                  return (
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                      key={ind}
                    >
                      <img
                        alt=""
                        src={ImgUrl("item") + "/" + b.photo}
                        onError={addDefaultSrc}
                        style={{
                          width: "180px",
                          height: "140px",
                          padding: "5px",
                          border: "1px solid #e2e2e2",
                          objectFit: "cover",
                          margin: "5px",
                        }}
                      />
                      <Delete
                        style={{
                          background: "#000",
                          width: "18px",
                          fill: "#fff",
                          padding: "3px",
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                        }}
                        onClick={(e) => {
                          productImg.splice(ind, 1);
                          setProductImg([...productImg]);
                          values.images = [...productImg];
                        }}
                      />
                    </div>
                  );
                })}

                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    setProductImg([
                      ...productImg,
                      { photo: newValue, thumb_photo: thumb_val },
                    ]);
                    values.images = [
                      ...productImg,
                      { photo: newValue, thumb_photo: thumb_val },
                    ];
                  }}
                  id="thumb_photo"
                  image={
                    values.photo ? ImgUrl("item") + "/" + values.photo : ""
                  }
                  type="item"
                  style={{ height: "140px", paddingTop: "50px" }}
                  divstyle={{
                    margin: "0 auto",
                    display: "inline-flex",
                    minWidth: "220px",
                  }}
                />
              </div>

              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children="Create"
                style={{ width: "calc(100% - 30px)", margin: "15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
