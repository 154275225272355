import React, { useContext, useState } from "react";
import { LogoutService, ApiService, ImgUrl } from "../services";
import GView from "../Elements/GView";
import styled from "styled-components";
import ChangePassword from "../forms/ChangePassword";
import EditProfile from "../forms/editProfile";
import GSwitch from "../Elements/gSwitch";
import Modal from "react-modal";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import GInfo from "../Elements/gInfo";
import {
  Button,
  MenuItem,
  MenuList,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  Divider,
  Avatar,
} from "@mui/material";
import GConfirmDialog from "../Elements/gConfirmDialog";
import { useSnackbar } from "notistack";

const Header = styled.header`
  background-color: rgb(21, 21, 21);
  height: 50px;

  a {
    padding: 0 10px;
    text-decoration: none;
    color: #333;
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
  }
`;

const Div = styled.div`
  p {
    color: ${({ theme }) => theme.body}!important;
  }
`;

const PaperTheme = styled(Paper)`
  margin-top: 5px;

  ul {
    display: block;
    list-style-type: none;
    padding: 7px 0;
    margin: 0;
    li {
      padding: 6px 17px;
      text-decoration: none;
      color: rgb(51, 51, 51);
      cursor: pointer;
      display: block;
      text-align: left;
      font-weight: 550;
      font-size: 15px !important;
      font-family: inherit;

      &:hover {
        background: #e2e2e2;
        color: #000;
      }
    }
  }
`;

const HeaderView = () => {
  const { settings, setSettings, refreshSettings } =
    useContext(SettingsContext);
  const [cpvisible, setCpvisible] = React.useState(false);
  const [epvisible, setEpvisible] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = (event) => {
    setOpen(false);
    setPlacement();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const customStyles = {
    content: {
      width: "400px",
    },
  };

  const handleLogout = () => {
    LogoutService("logout")
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          console.log(response);
          localStorage.setItem("hAccessToken", "");
          localStorage.setItem("hRefreshToken", "");
          localStorage.setItem("hExpiresIn", 0);
          window.location = "/";
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleStatus = (value) => {
    ApiService({ method: "PUT", route: "me", body: { host_status: value } })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          let updatedUserData = response.data.data;
          setSettings({ ...settings, user: updatedUserData });
          refreshSettings();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Header>
      <div style={{ marginTop: "0", marginBottom: "0" }}>
        <GView
          style={{ display: "flex", padding: "5px 0", alignItems: "center" }}
        >
          <div
            style={{
              display: "inline-flex",
              width: "60%",
              margin: "0 20px",
              position: "relative",
              height: "100%",
              alignItems: "center",
            }}
          >
            {/* <SearchComponent settings={settings} /> */}
            <GText
              text={"Accepting Bookings"}
              style={{
                color: "#f5f5f5",
                width: "auto",
                marginRight: "10px",
                fontsize: "14px",
              }}
            />

            <GSwitch
              id={settings?.user?.id}
              uId={settings?.user?.id}
              currentValue={settings?.user?.host_status == 1 ? 1 : 0}
              onSwitchChange={(newVal, id) => toggleStatus(newVal)}
            />
          </div>

          <GView
            style={{
              marginLeft: "auto",
              marginRight: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              aria-controls={open ? "menu-list-grow" : undefined}
              onClick={handleClick("bottom-end")}
              aria-haspopup="true"
              className="dropdownMenu"
              style={{ padding: "0" }}
            >
              <Avatar
                alt={settings?.user?.first_name}
                src={ImgUrl("host") + "/" + settings?.user?.photo}
                style={{
                  background: "#fff",
                  color: "#000",
                }}
              />
              &nbsp;&nbsp;
              <GText
                text={`${settings?.user?.first_name} ${
                  settings?.user?.last_name || ""
                }`}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "100px",
                  textAlign: "left",
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              />
            </Button>

            <Popper
              style={{ zIndex: "99" }}
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              role={undefined}
              transition
            >
              {({ TransitionProps }) => {
                return (
                  <Grow {...TransitionProps}>
                    <PaperTheme>
                      <Div
                        style={{
                          padding: 15,
                          margin: 0,
                          fontSize: 15.5,
                          lineHeight: "21px",
                        }}
                      >
                        <GInfo
                          title={
                            settings?.user?.first_name +
                            " " +
                            settings?.user?.last_name
                          }
                          key={settings?.user.id}
                          subtitle={settings?.user?.email}
                          photo={settings?.user?.photo}
                          imgType="host"
                          titleStyle={{ fontSize: "17px" }}
                        />
                      </Div>

                      <Divider />
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            onClick={() => {
                              setEpvisible(true);
                              setAnchorEl(null);
                              setOpen(false);
                            }}
                          >
                            Edit Profile
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setCpvisible(true);
                              setAnchorEl(null);
                              setOpen(false);
                            }}
                          >
                            Change Password
                          </MenuItem>
                          <MenuItem onClick={() => setDialogOpen(true)}>
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </PaperTheme>
                  </Grow>
                );
              }}
            </Popper>
          </GView>
        </GView>
      </div>

      <Modal
        isOpen={cpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCpvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "310px",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Change password Modal"
      >
        <ChangePassword cpvisible={cpvisible} setCpvisible={setCpvisible} />
      </Modal>
      <Modal
        isOpen={epvisible}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: "390px",
          },
        }}
        contentLabel="Edit Profile Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Logout"
          text="Are you sure you want to Logout?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleLogout();
          }}
        ></GConfirmDialog>
      )}
    </Header>
  );
};

export default HeaderView;
