import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import "./assets/css/modal.css";
import "./assets/css/forms.css";
import Login from "./Login";
import Header from "./Parts/header";
import Sidebar from "./Parts/sidebar";
import BookingInvoice from "./Pages/bookingInvoice";
import Bookings from "./Pages/bookingsList";
import BookingDetail from "./Pages/bookingDetails";
import BookingThermalInvoice from "./Pages/bookingThermalInvoice";
import Products from "./Pages/productsList";
import Reviews from "./Pages/reviewsList";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useMatch,
} from "react-router-dom";
import useSettings from "./useSettings";
import { ThemeStore } from "./DataFactory/useTheme";
import Theme from "./DataFactory/theme";
import Modal from "react-modal";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./errorView";
import NotFound from "./notFound";
import { SettingsProvider } from "./DataFactory/useSettings";
import Wallet from "./Pages/wallet";
import Faqs from "./Pages/faqs";
import Dashboard from "./Pages/dashboard";

const contentStyle = {
  background: "#f4f5f9",
  width: "82%",
  height: "100vh",
  overflow: "auto",
};

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
}

function App() {
  const location = useLocation();
  const setting = useSettings(true);
  const [toogleSidebar, setToggleSidebar] = useState(false);
  let settings = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : localStorage.getItem("settings");

  let match = useMatch("/booking-invoice/:bookingId");
  let match2 = useMatch("/booking-thermal-invoice/:bookingId");
  let url = match ? match?.pathname : "";
  let tUrl = match2 ? match2?.pathname : "";

  const isLoggedIn = () => {
    console.log(settings);
    // return true;
    if (
      localStorage.getItem("teamId") &&
      localStorage.getItem("hAccessToken") &&
      localStorage.getItem("hExpiresIn") > 0
      //  && settings?.user?.id
    ) {
      return true;
    } else {
      return false;
    }
  };

  Modal.setAppElement("#root");
  useEffect(() => {
    console.log(location);
  }, [location]);

  return (
    <ThemeStore>
      <Theme>
        {isLoggedIn() &&
        !(url === location.pathname || tUrl === location.pathname) ? (
          <SettingsProvider>
            <div
              className="App"
              id="webElement"
              style={{ display: "inline-flex", width: "100%" }}
            >
              <Sidebar
                style={{
                  width: toogleSidebar == true ? "0px" : "18%",
                  minWidth: toogleSidebar == true ? "0px" : "175px",
                  transition: "width 0.5s ,min-width 0.5s",
                }}
                toggle={toogleSidebar}
              />
              <div
                style={Object.assign({}, contentStyle, {
                  width: toogleSidebar == true ? "100%" : "82%",
                  transition: "width 0.5s",
                })}
              >
                <Header />
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => {
                    window.location.reload(false);
                  }}
                >
                  <div
                    className="mainInnerStyles"
                    style={{
                      height: "calc(100vh - 50px)",
                      width: "100%",
                      overflow: "overlay",
                      overflow: "auto",
                      position: "relative",
                    }}
                  >
                    <Routes>
                      <Route
                        path="/*"
                        element={
                          <Navigate
                            replace
                            to="dashboard"
                            state={"dashboard"}
                          />
                        }
                      />
                      <Route
                        path="dashbaord"
                        element={<Navigate replace to="/" />}
                      />
                      <Route path="dashboard" element={<Dashboard />}></Route>
                      <Route path="bookings" element={<Bookings />}></Route>
                      <Route
                        path="booking/:bookingId"
                        element={<BookingDetail />}
                      ></Route>
                      <Route path="products" element={<Products />}></Route>
                      <Route path="reviews" element={<Reviews />}></Route>
                      <Route path="wallet" element={<Wallet />}></Route>
                      <Route path="faqs" element={<Faqs />}></Route>
                    </Routes>
                  </div>
                </ErrorBoundary>
              </div>
            </div>
          </SettingsProvider>
        ) : (
          <Routes>
            {location?.pathname == "/" ? (
              <Route
                path="/*"
                element={<Navigate replace to="/dashboard" state="dashboard" />}
              />
            ) : (
              <Route path="/dashboard" element={<Navigate replace to="/" />} />
            )}
            <Route exact path="/" element={<Login />}></Route>
            <Route
              path="booking-invoice/:bookingId"
              element={<BookingInvoice />}
            ></Route>
            <Route
              path="booking-thermal-invoice/:bookingId"
              element={<BookingThermalInvoice />}
            ></Route>
          </Routes>
        )}
      </Theme>
    </ThemeStore>
  );
}

export default App;
