import dashboardIcon from "../assets/images/sidebar/dashboard.svg";
import orderIcon from "../assets/images/sidebar/orders.svg";
import productIcon from "../assets/images/sidebar/products.svg";
import reviewIcon from "../assets/images/sidebar/reviews.svg";
import withdrawalIcon from "../assets/images/sidebar/withdrawals.svg";
import { getUserType, getProductTitle } from "../services";

export const menus = [
  {
    icon: dashboardIcon,
    title: "Dashboard",
    path: "/dashboard",
    state: "dashboard",
    isLink: true,
  },
  {
    icon: orderIcon,
    title: "Bookings",
    path: "/bookings",
    state: "booking",
    isLink: true,
  },
  {
    icon: productIcon,
    title: getProductTitle(),
    path: "/products",
    state: "product",
    isLink: true,
  },
  {
    icon: reviewIcon,
    title: "Reviews",
    path: "/reviews",
    state: "review",
    isLink: true,
  },
  {
    icon: withdrawalIcon,
    title: "Wallet",
    state: "wallet",
    isLink: true,
    path: "/wallet",
  },
];
