import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../services";
import { Avatar } from "@mui/material";
import GImageEditor from "./gImageEditor";
import { Link } from "react-router-dom";
import GText from "../Elements/GText";

const ItemImage = styled.div`
  margin-right: 15px;
  .MuiAvatar-root {
    height: 50px;
    width: 50px;
  }
  &.headerInfo {
    .MuiAvatar-root {
      height: 50px;
      width: 50px;
      text-transform: uppercase;
      background: #ebae25;
      color: #fff;
    }
    .MuiAvatar-fallback {
      width: 85%;
      height: 85%;
    }
  }
`;

const ItemText = styled.div`
  width: 70%;
  p {
    margin: 2px 0;
  }
`;

export default function GInfo(props) {
  const [infoData, setInfoData] = useState({});
  const handleUpdate = (newValue, id, thumbValue) => {
    props.uploadImage(newValue, id, thumbValue);
  };
  useEffect(() => {
    setInfoData(props);
  }, [props]);

  return (
    <div
      style={Object.assign(
        {},
        { display: "inline-flex", width: "100%" },
        props.style
      )}
    >
      <ItemImage className={infoData.className}>
        {infoData.imgEdit === true ? (
          <GImageEditor
            image={ImgUrl(infoData.imgType) + "/" + infoData.photo}
            alt={infoData.title}
            onSubmit={handleUpdate}
            id={infoData.id}
            type={infoData.imgType}
            isPreviewImg={true}
          />
        ) : infoData.photo ? (
          <img
            alt={infoData.title}
            src={
              infoData.imgType
                ? ImgUrl(infoData.imgType) + "/" + infoData.photo
                : infoData.photo
            }
            style={{
              height: "45px",
              width: "45px",
              borderRadius: "50%",
            }}
            onError={(e) => addDefaultSrc(e, infoData.imgType)}
          />
        ) : (
          <Avatar
            alt={infoData.title}
            src={ImgUrl(infoData.imgType) + "/" + infoData.photo}
          />
        )}
      </ItemImage>
      <ItemText className={infoData.className}>
        {infoData.rightText && (
          <GText
            g4
            semi
            text={infoData.rightText}
            style={infoData.rightTextStyle}
          />
        )}
        {infoData.link ? (
          <Link to={{ pathname: infoData.link, state: infoData.linkState }}>
            <GText g4 theme semi text={infoData.title} />
          </Link>
        ) : infoData.className === "headerInfo" ? (
          <GText g3 semi text={infoData.title} style={{ margin: "5px 0" }} />
        ) : (
          <GText g4 theme semi text={infoData.title} />
        )}

        <GText text={infoData.subtitle} />
        {infoData.subtitle2 && <GText text={infoData.subtitle2} />}
      </ItemText>
    </div>
  );
}
