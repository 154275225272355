import React from "react";
import styled from "styled-components";
import Loader from "../assets/images/loader.svg";

const Button = styled.button`
  padding: 0.6em 1.5em;
  font-size: 15px;
  font-weight: 500;
  /* line-height: 1.5; */
  border-radius: 0px;
  cursor: pointer;
  width: fit-content;
  box-shadow: none;
  outline: 0;
  font-weight: 500;

  &.condensed {
    background: ${(props) => (props.primary ? "palevioletred" : "white")};
    color: ${(props) => (props.primary ? "white" : "palevioletred")};
    color: white;
    background: ${({ theme }) => theme.body};
    border: 0;
  }

  &[disabled] {
    /* color: #000; */
    cursor: not-allowed;
    background: #d2d2d2;
    border: 0px;

    &:hover {
      /* color: rgba(0, 0, 0, 0.66); */
    }
  }
  &.outlined {
    color: ${({ theme }) => theme.body};
    background: transparent;
    border: 1px solid ${({ theme }) => theme.body};
  }

  &.linkable {
    color: ${({ theme }) => theme.body};
    background: transparent;
    border: 0px;
    padding: 5px;
  }

  &.text {
    background: transparent;
    padding: 6px 12px;
    border: 0px;
    color: ${({ theme }) => theme.body};

    &:hover {
      background: rgba(25, 118, 210, 0.04);
    }
  }

  &.hide {
    display: none;
  }
`;

function GButton({ loading, children, ...props }) {
  return (
    <Button
      id={props.id}
      className={props.variant}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled || loading}
      type={props.type || "button"}
    >
      {children}
      {loading && (
        <>
          &nbsp;
          <img
            src={Loader}
            alt="loading"
            style={{ verticalAlign: "middle", float: "right" }}
          />
        </>
      )}
    </Button>
  );
}

export default GButton;
