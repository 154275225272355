import React, { useState, useContext } from "react";
import "./sidebar.css";
import { SettingsContext } from "../DataFactory/useSettings";
import GNestedMenu from "../Elements/gNestedMenu";
import { ImgUrl, addDefaultLogo } from "../services";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as SupportIcon } from "../assets/images/sidebar/support.svg";
import { ReactComponent as Mail } from "../assets/images/sidebar/mail.svg";
import { ReactComponent as Phone } from "../assets/images/sidebar/phone.svg";
import { ReactComponent as WhatsApp } from "../assets/images/sidebar/whatsapp.svg";

const SupportUl = styled.ul`
  margin: 0 15px !important;
  padding: 2px 0 5px !important;
  background: #444;
  li {
    padding: 4px 10px !important;
    a {
      color: #ccc !important;
      font-size: 13px !important;
      align-items: center;
      display: inline-flex !important;
      width: 100% !important;
      padding: 2px 0 !important;

      svg {
        width: 15px;
        height: 15px;
        padding-right: 10px;
        fill: #f5f5f5;
      }
    }
  }
`;

export default function Sidebar(props) {
  let teamId = localStorage.getItem("teamId");
  let location = useLocation();
  const { settings } = useContext(SettingsContext);

  return (
    <div className="Sidebar" style={props.style}>
      <div className="App-header">
        <img
          src={ImgUrl("logo") + "/" + settings?.admin_logo}
          className="App-logo"
          alt="logo"
          onError={addDefaultLogo}
        />
        <span>
          <h3>{settings.project_name}</h3>
          <p>{teamId}</p>
        </span>
      </div>

      <GNestedMenu settings={settings} />

      <h4>Other Information</h4>
      <ul className="pList">
        <li className={location.pathname === "/faqs" ? "active" : ""}>
          <Link to={{ pathname: "/faqs", state: "setting" }}>
            <span role="img" aria-label="help" className="icon">
              <SupportIcon
                style={{
                  width: "17px",
                  height: "17px",
                  fill: location.pathname === "/faqs" ? "#fff" : "#bbb",
                }}
              />
            </span>
            <span>Help & FAQ's</span>
          </Link>
        </li>
      </ul>

      <h4 style={{ marginTop: "25px" }}>Support</h4>

      <SupportUl>
        <li>
          <a href={"tel:" + settings?.admin_phone}>
            <Phone /> {settings?.admin_phone}
          </a>
        </li>
        <li>
          <a href={"mailTo:" + settings?.admin_email}>
            <Mail /> {settings?.admin_email}
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href={
              "https://api.whatsapp.com/send?phone=" + settings?.admin_whatsapp
            }
          >
            <WhatsApp /> {settings?.admin_whatsapp}
          </a>
        </li>
      </SupportUl>
    </div>
  );
}
