import React, { useEffect, useState } from "react";
import {
  ApiService,
  addDefaultSrc,
  ImgUrl,
  getProductTitle,
} from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../Elements/gImageEditor";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import ProductImages from "../Pages/productImages";
import { values } from "lodash";
import GMap from "../Elements/gMaps";
import GTabs from "../Elements/gTabs";
import ProductUnits from "../Pages/productUnits";

export default function ProductEditForm(props) {
  const productTypes = ["basic_info", "images"];
  const productTypes2 = ["basic_info", "images", props.settings?.unit_display];
  console.log(props);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [productImages, setProductImgaes] = useState(props.data.images || []);

  const [typeValue, setTypeValue] = useState(
    props.settings?.multiple_units == "2" ? productTypes2[0] : productTypes[0]
  );

  const initialValues = props.fields.reduce(
    (a, v) => ({
      ...a,
      [v?.id]: v?.value
        ? v.value
        : v?.type == "checkbox"
        ? v.value == 1
          ? 1
          : 0
        : "",
    }),
    {
      cost: props.data.cost,
      address: props.data?.address || "",
      lat: props.data?.lat || "",
      lng: props.data?.lng || "",
      units: props.data?.units || "",
      min_users: props.data?.min_users || "",
      max_users: props.data?.max_users || "",
      users_covered: props.data?.users_covered || 0,
      user_cost: props.data?.user_cost || "",
    }
  );
  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);

    let sendData = {
      cost: values.cost,
      user_cost: values.user_cost,
      users_covered: values.users_covered,
      custom_fields: values,
      address: values.address,
      lat: values.lat,
      lng: values.lng,
      units: values.units,
      min_users: values.min_users,
      max_users: values.max_users,
    };
    ApiService({
      method: "PUT",
      route: "products/" + props.data.id,
      body: sendData,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.setPevisible(false);
        props.onSubmit();
      }
      setIsBtnDisabled(false);
      enqueueSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    cost: props.settings?.time_charges == 1 ? Yup.string().required() : "",
    address: Yup.string().required(),
  }).shape(
    props.fields
      .filter((t) => t.required)
      .reduce(
        (a, v) => ({
          ...a,
          [(v?.id).toString()]:
            v.type == "checkbox"
              ? ""
              : Yup.string()
                  .nullable()
                  .required(v.title + " is required"),
        }),
        {}
      )
  );

  if (props.pevisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> Edit {getProductTitle()}</div>

      <div
        className="modal-body"
        style={{ padding: "10px 0", overflow: "hidden" }}
      >
        <GTabs
          routes={
            props.settings?.multiple_units == "2" ? productTypes2 : productTypes
          }
          active={typeValue}
          type="single"
          onClick={(newVal) => setTypeValue(newVal)}
          style={{ borderBottom: "1px solid #e2e2e2" }}
        ></GTabs>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {typeValue == "basic_info" && (
                <div
                  style={{
                    overflow: "overlay",
                    height: "calc(100vh - 160px)",
                    padding: "20px",
                  }}
                >
                  {props.settings?.time_charges == 1 && (
                    <FormikControl
                      control="input"
                      type="text"
                      label={"Cost (" + props.settings?.billing_type + ")"}
                      name={"cost"}
                      divstyle={{
                        display: "inline-block",
                        width:
                          props.settings?.multiple_units == 0 ? "100%" : "33%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                      labelStyle={{ textTransform: "capitalize" }}
                    />
                  )}
                  {props.settings?.multiple_units > 0 &&
                    props.settings?.time_charges == 1 && (
                      <>
                        {" "}
                        <FormikControl
                          control="input"
                          type="text"
                          label={"User Cost"}
                          name={"user_cost"}
                          divstyle={{
                            display: "inline-block",
                            width: "33%",
                            boxSizing: "border-box",
                            verticalAlign: "top",
                          }}
                          onChange={(event) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              event.target.value &&
                              !re.test(event.target.value)
                            ) {
                              return;
                            } else {
                              setFieldValue("user_cost", event.target.value);
                            }
                          }}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label={"User Covered"}
                          name={"users_covered"}
                          divstyle={{
                            display: "inline-block",
                            width: "33%",
                            boxSizing: "border-box",
                            verticalAlign: "top",
                          }}
                          onChange={(event) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              event.target.value &&
                              !re.test(event.target.value)
                            ) {
                              return;
                            } else {
                              setFieldValue(
                                "users_covered",
                                event.target.value
                              );
                            }
                          }}
                        />
                      </>
                    )}

                  {props.settings?.multiple_units == 1 && (
                    <>
                      <FormikControl
                        control="input"
                        type="text"
                        label={props.settings?.unit_display + " Count"}
                        name={"units"}
                        divstyle={{
                          display: "inline-block",
                          width: "33%",
                          boxSizing: "border-box",
                          verticalAlign: "top",
                        }}
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            event.target.value &&
                            !re.test(event.target.value)
                          ) {
                            return;
                          } else {
                            setFieldValue("units", event.target.value);
                          }
                        }}
                      />

                      <FormikControl
                        control="input"
                        type="text"
                        label={"Min Users"}
                        name={"min_users"}
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            event.target.value &&
                            !re.test(event.target.value)
                          ) {
                            return;
                          } else {
                            setFieldValue("min_users", event.target.value);
                          }
                        }}
                        divstyle={{
                          display: "inline-block",
                          width: "33.3%",
                          boxSizing: "border-box",
                          verticalAlign: "top",
                        }}
                      />
                      <FormikControl
                        control="input"
                        type="text"
                        label={"Max Users"}
                        name={"max_users"}
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            event.target.value &&
                            !re.test(event.target.value)
                          ) {
                            return;
                          } else {
                            setFieldValue("max_users", event.target.value);
                          }
                        }}
                        divstyle={{
                          display: "inline-block",
                          width: "33.3%",
                          boxSizing: "border-box",
                          verticalAlign: "top",
                        }}
                      />
                    </>
                  )}
                  <GMap
                    centerLocation={{
                      lat: values?.lat ? parseFloat(values.lat) : 30.7046,
                      lng: values?.lng ? parseFloat(values.lng) : 76.7179,
                    }}
                    search={true}
                    height="30vh"
                    type="createStore"
                    onChange={(address, loc) => {
                      values.lat = loc.lat();
                      values.lng = loc.lng();
                      setFieldValue("address", address.address);
                    }}
                    label="Location"
                    padding={true}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label="Address"
                    name="address"
                  />
                  {props.fields.map((f, i) => (
                    <React.Fragment key={i}>
                      {f.type == "select" && (
                        <FormikControl
                          control="selectOption"
                          as="select"
                          label={f.title}
                          name={f.id.toString()}
                          options={f.answers}
                          key_title="title"
                          key_value="id"
                          divstyle={{
                            display: "inline-block",
                            width: "50%",
                            boxSizing: "border-box",
                            verticalAlign: "top",
                          }}
                        />
                      )}
                      {f.type == "text" && (
                        <FormikControl
                          control="input"
                          type="text"
                          label={f.title}
                          name={f.id}
                        />
                      )}
                      {f.type == "textarea" && (
                        <FormikControl
                          control="input"
                          as="textarea"
                          label={f.title}
                          name={f.id}
                        />
                      )}

                      {f.type == "checkbox" && (
                        <FormikControl
                          control="singleCheckbox"
                          as="checkbox"
                          label={f.title}
                          name={f.id}
                          checked={values[f.id] == 1 ? true : false}
                          onChange={(e) => {
                            values[f.id] = e.target.checked == true ? 1 : 0;
                            setFieldValue(
                              f.id,
                              e.target.checked == true ? 1 : 0
                            );
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}

                  <GButton
                    type="submit"
                    disabled={isBtnDisabled}
                    variant="condensed"
                    children="Update"
                    style={{ width: "calc(100% - 30px)", margin: "15px" }}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>

        {typeValue == "images" && (
          <div
            style={{
              overflow: "overlay",
              height: "calc(100vh - 160px)",
              padding: "20px",
            }}
          >
            {/* <GText semi g2 text={getProductTitle() + " Images"} /> */}
            <div style={{ margin: "15px" }}>
              <ProductImages
                onUpload={(data) => {
                  setProductImgaes([...productImages, data]);
                }}
                onRemove={(id) => {
                  setProductImgaes(
                    productImages.filter((item) => item.id !== id)
                  );
                }}
                productImages={productImages}
                add={true}
                productId={props.data.id}
              />
            </div>
          </div>
        )}

        {typeValue == props.settings?.unit_display && (
          <div
            style={{
              overflow: "overlay",
              height: "calc(100vh - 160px)",
              padding: "20px",
            }}
          >
            <ProductUnits productId={props.data?.id} />
          </div>
        )}
      </div>
    </>
  );
}
