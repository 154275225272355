import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import { ApiService } from "../services";
import { ReactComponent as ThumbDownAlt } from "../assets/images/thumbs-down.svg";
import { ReactComponent as ThumbUpAlt } from "../assets/images/thumbs-up.svg";
import GEmpty from "../Elements/gEmpty";
import GTableShimmer from "../Elements/gTableShimmer";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import GTabs from "../Elements/gTabs";

export const FaqDiv = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid #e2e2e2;

  &:last-child {
    border: 0;
  }
  h3 {
    margin: 10px 0;
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: #555;
    white-space: pre-line;
  }
  h5 {
    margin: 10px 0;
    color: #a2a2a2;
    font-size: 15px;
    font-weight: 500;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

export default function Faqs() {
  const { settings } = useContext(SettingsContext);
  const faqTypes = ["order", "offers", "account", "other"];
  const [typeValue, setTypeValue] = useState(faqTypes[0]);
  const [faqData, setFaqData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeTab = (newVal) => {
    console.log(newVal);
    setTypeValue(newVal);
  };

  const handleQuery = (value, id) => {
    ApiService({
      method: "POST",
      route: "faq/feedback",
      body: { faq_id: id, liked: value },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getFaqList();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFaqList = () => {
    ApiService({ method: "GET", route: "faqs" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setFaqData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getFaqList();
  }, []);

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className={"mainInnerView"}>
      <h1>Help & FAQ's </h1>

      <GTabs
        routes={faqTypes}
        active={typeValue}
        type="single"
        onClick={(s) => setTypeValue(s)}
      ></GTabs>
      <div className="listPaper" style={{ marginTop: "0" }}>
        {isLoading === true ? (
          <GTableShimmer></GTableShimmer>
        ) : faqData.filter((r) => r.faq_type === typeValue).length === 0 ? (
          <GEmpty />
        ) : (
          <div style={{ maxHeight: "calc(90vh - 213px)", overflow: "overlay" }}>
            {faqData
              .filter((r) => r.faq_type === typeValue)
              .map((f, i) => {
                return (
                  <FaqDiv key={i}>
                    <h3>{f.question}</h3>
                    <p>{f.answer}</p>
                    <div style={{ textAlign: "right" }}>
                      <h5>
                        Was this answer helpful ?
                        <ul
                          style={{
                            display: "inline-flex",
                            color: "#ccc",
                            listStyleType: "none",
                            margin: "0 0 10px",
                            padding: "0 5px",
                          }}
                        >
                          <li
                            style={{ padding: "0 5px", cursor: "pointer" }}
                            onClick={() => handleQuery("1", f.id)}
                          >
                            <ThumbUpAlt
                              fontSize="small"
                              style={{ verticalAlign: "bottom" }}
                            />
                          </li>
                          |
                          <li
                            style={{ padding: "0 5px", cursor: "pointer" }}
                            onClick={() => handleQuery("2", f.id)}
                          >
                            <ThumbDownAlt
                              fontSize="small"
                              style={{
                                verticalAlign: "bottom",
                              }}
                            />
                          </li>
                        </ul>
                      </h5>
                    </div>
                  </FaqDiv>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
